import React from 'react'
import classes from './LoadingButton.module.css'
import { Button, Spinner } from 'reactstrap'
const LoadingButton = (props) => {
    return (
        <Button color={props.color || 'primary'}
            className={classes.saveButton + ' ' + props.className}
            style={props.disabled ? { ...props.style, backgroundColor: 'gray', cursor: 'default' } : props.loading ? { ...props.style, cursor: 'default' } : props.style}
            onClick={!props.loading && !props.disabled ? props.onClick : null}>
            {props.loading && <Spinner style={{ height: '20px', width: '20px', marginRight: '5px' }} />}
            <span> {props.children}</span>
        </Button>
    )
}

export default LoadingButton