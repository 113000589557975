import React from 'react';
import { Button, Modal } from 'reactstrap';
import classes from './ConfirmModal.module.css'

const ConfirmModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={() => { }} centered contentClassName={classes.contentClassName}>
            <div className={classes.headerContainer}>
                <h5 className={classes.pieceNameText}>{'Confirmer votre choix'}</h5>
            </div>


            <div className={classes.modalBodyContainer}>
                <p className={classes.modalContentText}>
                    {props.message}
                </p>
            </div>

            <div className={classes.modalFooter}>

                <Button className={classes.modalButtonRefuse} color="primary" onClick={props.onReject}>Non</Button>
                <Button className={classes.modalButtonAccept} color="primary" onClick={props.onAccept}>Oui</Button>
            </div>
        </Modal>
    );
}
export default ConfirmModal;