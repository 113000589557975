import { activateMusic } from 'Admin/api'
import { updateMusic } from 'Admin/api'
import { deleteMusic } from 'Admin/api'
import { getMusics } from 'Admin/api'
import React from 'react'
import Items from './Items/Items'
function Musics() {
    return (
        <Items
            getData={getMusics}
            activateItem={activateMusic}
            updateItemInfo={updateMusic}
            getItemPath="music"
            itemName="music"
            deleteItem={deleteMusic}
            tableTitles={['Referance', 'Title', 'Exclusive', 'Discount', 'Date', 'Artist', 'Category']}
        />
    )
}

export default Musics