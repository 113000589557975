
import React from 'react'
import { Col, Container, Row, Input, Button } from 'reactstrap'
import classes from './Login.module.css'
import logo from '../assets/img/logo.png'
import { userLogin } from './api'
import LocalStorageService from 'utils/localStorageService'
import GlobalContext from 'context/GlobalContext'
import { useHistory } from 'react-router-dom'
import LoadingButton from 'components/LoadingButton/LoadingButton'
const Login = () => {

    const [identifier, setIdentifier] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(null)
    const context = React.useContext(GlobalContext)
    const history = useHistory()
    React.useEffect(() => {
        if (context.user) {
            history.replace('/welcome')
        }
    }, [])
    const loginHandler = async () => {
        try {
            setLoading(true)
            const accessToken = await userLogin(identifier, password)
            LocalStorageService.setToken(accessToken)
            setLoading(false)
            window.location.href = "/welcome"
        } catch (error) {
            setLoading(false)
            setError('Email ou mot de passe incorrecte')
        }
    }
    const keyPressedHandler = (e) => {
        if (e.key === 'Enter') {
            loginHandler()
        }
    }
    return (
        <Container fluid className={classes.mainContainer}>
            <Row className={classes.mainRow}>
                <Col>
                    <Row>
                        <Col>
                            <div className={classes.logoContainer}>
                                <img src={logo} className={classes.logoImage} />

                            </div>
                        </Col>
                    </Row>
                    <Row className={classes.loginContainer}>
                        <Col xs="12" md="3" className={classes.mainCol}>
                            <div>
                                <h5 className={classes.mainTitle}>Se connecter a votre compte</h5>
                            </div>
                            <div className={classes.itemContainer}>
                                <h5 className={classes.itemText}>Email/Phone</h5>
                                <Input value={identifier}
                                    onFocus={() => setError(null)}
                                    onKeyPress={keyPressedHandler}
                                    onChange={(e) => setIdentifier(e.target.value)} className={classes.input} />

                            </div>
                            <div className={classes.itemContainer}>
                                <h5 className={classes.itemText}>Mot de passe</h5>
                                <Input value={password} type="password"
                                    onFocus={() => setError(null)}
                                    onKeyPress={keyPressedHandler}
                                    onChange={(e) => setPassword(e.target.value)} className={classes.input} />

                            </div>
                            {error && <div className={classes.itemContainerError}>
                                <h5 className={classes.itemTextError}>{error}</h5>

                            </div>}
                            <div className={classes.itemContainer}>
                                <LoadingButton color="primary" loading={loading} className={classes.loginButton} onClick={loginHandler}>Login</LoadingButton>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default Login
