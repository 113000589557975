import React from 'react'
import classes from './MainNavItem.module.css'
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";

const MainNavItem = (props) => {

    const [isOpen, setIsOpen] = React.useState(false)
    const activeRoute = (routeName) => {
        return props.location.pathname.includes(routeName) ? "active" : "";
    };
    return (
        <div className={classes.mainContainer} >
            <div className={classes.mainNavItemContainer} onClick={() => { setIsOpen(!isOpen) }}>
                <i className={props.mainRoute.icon} style={{ color: 'black' }} />
                <div className={classes.mainNavItemContent}>
                    <p style={{ color: 'black' }}>{props.mainRoute.title}</p>
                    <i className={`now-ui-icons arrows-1_minimal-${isOpen ? 'up' : 'down'}`} style={{ color: 'black', fontSize: '15px' }}></i>
                </div>
            </div>
            <Collapse
                isOpen={isOpen}
            >
                {props.mainRoute.routes.map((route, key) => {
                    if (route.redirect) return null;
                    return (
                        <li
                            className={
                                activeRoute(`${route.layout}${route.path}`)
                            }
                            key={key}
                        >
                            <Link
                                to={`${route.layout}${route.path}`}
                                className="nav-link"
                            >
                                <i className={route.icon} style={{ color: 'black' }} />
                                <p style={{ fontWeight: '600', fontSize: '10px', color: 'black' }}>{route.name}</p>
                            </Link>
                        </li>
                    );
                })}
            </Collapse>
        </div>
    )
}

export default MainNavItem
