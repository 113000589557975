import axios from 'axios';
import localStorageService from './localStorageService';
const production = true
const apiHost = production ? 'https://www.musoundio.com/api' : 'http://localhost:5000';
const axiosInstance = axios.create({ baseURL: apiHost });

axiosInstance.interceptors.request.use(
    (config) => {
        const accessToken = localStorageService.getAccessToken();
        if (accessToken) {
            config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (error.response)
            if (error.response.status === 403) {
                window.location.href = '/welcome'

            } else if (error.response.status === 401) {
                localStorageService.clearToken();
                window.location.href = "/login";
            } else return Promise.reject(error);
        else return Promise.reject(error);
    }
);

export const host = apiHost;
export default axiosInstance;
