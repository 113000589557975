import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, Redirect } from "react-router-dom";
import IndexNavbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar.js";
import mainRoutes from "./routes.js";
import Music from "./pages/Music/Music/Music";
import Artist from "./pages/Artist/Artist/Artist";
import Category from "./pages/Category/Category/Category";
import Tag from "./pages/Tag/Tag/Tag";
import Order from "./pages/Orders/Order/Order";


var ps;

const AdminModule = (props) => {
    const mainPanel = React.useRef();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }

        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });
    return (
        <div className="wrapper">
            <Sidebar {...props} routes={mainRoutes} />
            <div className="main-panel" style={{ padding: '100px 0px 40px 0px' }} ref={mainPanel}>
                <IndexNavbar {...props} />
                <Switch>
                    {mainRoutes.map((mainRoute, key) => {
                        return mainRoute.routes.map(route => (
                            <Route

                                path={route.layout + route.path}
                                component={route.component}
                                key={key}
                            />
                        ))
                    })}
                    <Route path='/admin/music/:musicId' component={Music} />
                    <Route path='/admin/category/:categoryId' component={Category} />
                    <Route path='/admin/artist/:artistId' component={Artist} />
                    <Route path='/admin/order/:orderId' component={Order} />

                    <Route path='/admin/tag/:tagId' component={Tag} />

                    <Redirect to="/admin" />
                </Switch>
            </div>

        </div>
    );
}

export default AdminModule;
