import { updateArtist } from 'Admin/api'
import { getArtist } from 'Admin/api'
import React from 'react'
import GetItem from './GetItem/GetItem'

const Artist = (props) => {
    return (
        <GetItem
            itemName="Artist"
            itemId={props.match.params.artistId}
            updateItem={updateArtist}
            getItem={getArtist}
        />
    )
}

export default Artist