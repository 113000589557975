import React from 'react'
import logo from '../../assets/img/logo.png'
import classes from './LoadingPage.module.css'
const LoadingPage = () => {
    return (
        <div className={classes.mainContainer}>
            <img src={logo} className={classes.logoImg} alt="logo" />
        </div>
    )
}

export default LoadingPage
