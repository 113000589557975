import { addCategory } from 'Admin/api'
import React from 'react'
import AddItem from './AddItem/AddItem'

const AddCategory = () => {
    return (
        <AddItem
            itemName="Category"
            addItem={addCategory}
        />
    )
}

export default AddCategory