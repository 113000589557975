import React from 'react';
import { Row, Col, Button, Modal, Input } from 'reactstrap';
import classes from './AddTagModal.module.css'

const AddTagModal = (props) => {
    const toggle = () => props.toggle(!props.isOpen);
    const [tagInfo, setTagInfo] = React.useState({})

    React.useEffect(() => {
        if (!props.isOpen) {
            setTagInfo({})
        }
    }, [props.isOpen])

    return (
        <Modal isOpen={props.isOpen} toggle={toggle} centered contentClassName={classes.contentClassName}>
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Nom</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setTagInfo({ ...tagInfo, name: e.target.value })}
                                    value={tagInfo.name || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            <div className={classes.modalFooter}>

                <Button className={classes.modalButtonRefuse} color="primary" onClick={toggle}>Annuler</Button>
                <Button className={classes.modalButtonAccept} color="primary" onClick={() => props.onConfirm(tagInfo)}>Confirmer</Button>
            </div>
        </Modal>
    );
}
export default AddTagModal;