import { updateCategory } from 'Admin/api'
import { getCategory } from 'Admin/api'
import React from 'react'
import GetItem from './GetItem/GetItem'

const Category = (props) => {
    return (
        <GetItem
            itemName="Category"
            itemId={props.match.params.categoryId}
            updateItem={updateCategory}
            getItem={getCategory}
        />
    )
}

export default Category