import LoadingButton from 'components/LoadingButton/LoadingButton'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import classes from './GetItem.module.css'
function GetItem(props) {

    const [itemInfo, setItemInfo] = React.useState({})
    const [isError, setIsError] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loadingUpdate, setLoadingUpdate] = React.useState(false)

    const [isSuccess, setIsSuccess] = React.useState(false)

    React.useEffect(() => {
        props.getItem(props.itemId)
            .then(_item => {
                setItemInfo(_item)
                setLoading(false)
            })
    }, [])


    const saveItemHandler = () => {
        if (!itemInfo.name)
            return setIsError('Please fill the whole inputs')
        setLoadingUpdate(true)
        props.updateItem(props.itemId, itemInfo)
            .then(res => {
                setIsError(null)
                setIsSuccess(true)
                setLoadingUpdate(false)

            })
            .catch(err => {
                setIsError('An error occured')
                setLoadingUpdate(false)
            })
    }
    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [itemInfo])
    if (loading)
        return <LoadingSpinner />
    return (
        <Container>
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Name</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, name: e.target.value })}
                                    value={itemInfo.name || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            {
                isError && <Row >
                    <Col className={classes.modalFooter}>
                        <h5 className={classes.infoMessage} style={{ color: 'red' }}>Please fill the whole inputs</h5>
                    </Col>
                </Row>
            }
            {
                !isError && isSuccess && <Row >
                    <Col className={classes.modalFooter}>
                        <h5 className={classes.infoMessage} style={{ color: 'green' }}>{props.itemName} successfully added</h5>
                    </Col>
                </Row>
            }
            <Row >
                <Col className={classes.modalFooter}>
                    <LoadingButton loading={loadingUpdate} className={classes.modalButtonAccept} color="info"
                        onClick={saveItemHandler}>Save</LoadingButton>
                </Col>
            </Row>
        </Container >
    )
}

export default GetItem