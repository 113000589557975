import GlobalContext from "context/GlobalContext";
import React from "react";
import classes from './Navbar.module.css'
import { useLocation, useHistory } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container,

} from "reactstrap";
import FormateDate from "utils/FormatDate";



const NavbarIndex = (props) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const sidebarToggle = React.useRef();
    const context = React.useContext(GlobalContext)
    const toggle = () => {
        setIsOpen(!isOpen);
    };
    const dropdownToggle = (e) => {
        setDropdownOpen(!dropdownOpen);
    };

    const openSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        sidebarToggle.current.classList.toggle("toggled");
    };
    React.useEffect(() => {
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
            sidebarToggle.current.classList.toggle("toggled");
        }
    }, [location]);


    return (

        <Navbar
            expand="lg"
            className={"navbar-absolute fixed-top"}
            style={{ position: 'fixed', top: '0', left: '0', backgroundColor: '#1B2039' }}
        >
            <Container fluid>
                <div className="navbar-wrapper">
                    <div className="navbar-toggle">
                        <button
                            type="button"
                            ref={sidebarToggle}
                            className="navbar-toggler"
                            onClick={() => openSidebar()}
                        >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </button>
                    </div>
                    <NavbarBrand >{"Musoundio"}</NavbarBrand>
                </div>
                <NavbarToggler onClick={toggle}>
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                    <span className="navbar-toggler-bar navbar-kebab" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar className="justify-content-end">
                    <Nav navbar>
                        <Dropdown
                            nav
                            isOpen={dropdownOpen}
                            toggle={(e) => dropdownToggle(e)}
                        >
                            <DropdownToggle caret nav>
                                <i className="now-ui-icons location_world" />
                                <p>
                                    <span className="d-lg-none d-md-block">Parametres</span>
                                </p>
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem className={classes.menuControlItem} onClick={() => { window.location.href = "/welcome" }}>
                                    Acceuil
                                </DropdownItem>

                                <DropdownItem className={classes.menuControlItem} onClick={() => context.userLogout()}>Se Deconnecter</DropdownItem>

                            </DropdownMenu>
                        </Dropdown>

                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    );
}

export default NavbarIndex;
