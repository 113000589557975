
import { updateCategory } from 'Admin/api'
import { deleteCategory } from 'Admin/api'
import { getCategories } from 'Admin/api'
import { activateCategory } from 'Admin/api'
import React from 'react'
import Items from './Items/Items'
function Categories() {
    return (
        <Items
            getData={getCategories}
            activateItem={activateCategory}
            updateItemInfo={updateCategory}
            deleteItem={deleteCategory}
            getItemPath="category"
            tableTitles={['Name', 'Date', 'Music count']}
        />
    )
}

export default Categories