import { addArtist } from 'Admin/api'
import React from 'react'
import AddItem from './AddItem/AddItem'

const AddArtist = () => {
    return (
        <AddItem
            itemName="Artist"
            addItem={addArtist}
        />
    )
}

export default AddArtist