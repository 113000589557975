import { addTag } from 'Admin/api'
import AddArtist from 'Admin/pages/Artist/AddArtist/AddArtist'
import React from 'react'
import AddItem from './AddItem/AddItem'

const AddTagComponent = () => {
    return (
        <AddItem
            itemName="Tag"
            addItem={addTag}
        />
    )
}

export default AddTagComponent