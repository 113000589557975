
import { getOrders } from 'Admin/api'
import { deleteOrder } from 'Admin/api'
import { activateOrder } from 'Admin/api'

import React from 'react'
import Items from './Items/Items'
function Orders() {
    return (
        <Items
            getData={getOrders}
            activateItem={activateOrder}
            getItemPath="order"
            itemName="order"
            deleteItem={deleteOrder}
            tableTitles={['ID', 'Date', 'Email', "Number of items"]}
        />
    )
}

export default Orders