import { uploadFile } from 'Admin/api'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import classes from './AddItem.module.css'
function AddItem(props) {

    const [itemInfo, setItemInfo] = React.useState({})
    const [isError, setIsError] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [previewItemImage, setPreviewItemImage] = React.useState(null)
    const inputFileRef = React.useRef(null)

    const readPieceImageUrl = (event) => {
        var reader = new FileReader();
        reader.onload = function () {
            setPreviewItemImage(reader.result);
        };
        reader.readAsDataURL(event.target.files[0]);
    }
    const addItemHandler = () => {
        if (!itemInfo.name || !itemInfo.image)
            return setIsError(true)

        const fd = new FormData()
        fd.append('music', itemInfo.image)
        setLoading(true)
        uploadFile(fd)
            .then(links => {
                props.addItem({ ...itemInfo, image: links[0] })
                    .then(res => {
                        setItemInfo({})
                        setIsError(false)
                        setIsSuccess(true)
                        setLoading(false)
                        setPreviewItemImage(null)

                    })
                    .catch(() => {
                        setIsError(true)
                        setLoading(false)
                    })
            })
            .catch(() => {
                setLoading(false)
            })
    }
    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [itemInfo])
    return (
        <Container>
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Image</h5>
                            <div className={classes.headerItemInfoContainer}>
                                <img className={classes.pieceImage} src={previewItemImage} />
                                <div className={classes.editImageContainer}>
                                    <input ref={inputFileRef} type="file" style={{ display: 'none' }} onChange={(e) => { setItemInfo({ ...itemInfo, image: e.target.files[0] }); readPieceImageUrl(e) }} />
                                    <i className={`fas fa-pencil-alt ${classes.editIcon}`} onClick={() => inputFileRef.current.click()}></i>
                                </div>
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Name</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, name: e.target.value })}
                                    value={itemInfo.name || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            {isError && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'red' }}>Please fill the whole inputs</h5>
                </Col>
            </Row>}
            {!isError && isSuccess && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'green' }}>{props.itemName} successfully added</h5>
                </Col>
            </Row>}
            <Row >
                <Col className={classes.modalFooter}>
                    <LoadingButton loading={loading} className={classes.modalButtonAccept} color="info"
                        onClick={addItemHandler}>Add</LoadingButton>
                </Col>
            </Row>
        </Container>
    )
}

export default AddItem