
import { getOffers } from 'Admin/api'
import { deleteOffer } from 'Admin/api'
import { activateOffer } from 'Admin/api'
import React from 'react'
import Items from './Items/Items'
function Offers() {
    return (
        <Items
            getData={getOffers}
            activateItem={activateOffer}
            deleteItem={deleteOffer}
            itemName="offer"
            tableTitles={['Date', 'Email', 'Reference', 'Music', 'Message']}
        />
    )
}

export default Offers