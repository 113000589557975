import { updateTag } from 'Admin/api'
import { getTag } from 'Admin/api'
import React from 'react'
import GetItem from './GetItem/GetItem'

const Tag = (props) => {
    return (
        <GetItem
            itemName="Tag"
            updateItem={updateTag}
            itemId={props.match.params.tagId}
            getItem={getTag}
        />
    )
}

export default Tag