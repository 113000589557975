
import Users from "./pages/Users/Users";
import Musics from "./pages/Music/Musics/Musics";
import AddMusic from "./pages/Music/AddMusic/AddMusic";
import Artists from "./pages/Artist/Artists/Artists";
import AddArtist from "./pages/Artist/AddArtist/AddArtist";
import AddTag from "./pages/Tag/AddTag/AddTag";

import Tags from "./pages/Tag/Tags/Tags";
import Categories from "./pages/Category/Categories/Categories";
import AddCategory from "./pages/Category/AddCategory/AddCategory";
import Orders from "./pages/Orders/Orders";
import Offers from "./pages/Offers/Offers";
import Settings from "./pages/Settings/Settings";


var dashRoutes = [
    {
        title: "Products",

        icon: "fa-solid fa-music",
        routes: [
            {
                path: "/musics",
                name: "All products",
                icon: "fa-solid fa-list",
                component: Musics,
                layout: "/admin",
            },
            {
                path: "/add-music",
                name: "Add product",
                icon: "fa-solid fa-square-plus",
                component: AddMusic,
                layout: "/admin",
            },
        ]
    },
    {
        title: "Artists",
        icon: "fa-solid fa-user",
        routes: [
            {
                path: "/artists",
                name: "All artists",
                icon: "fa-solid fa-list",
                component: Artists,
                layout: "/admin",
            },
            {
                path: "/add-artist",
                name: "Add artist",
                icon: "fa-solid fa-square-plus",
                component: AddArtist,
                layout: "/admin",
            }
        ]
    },
    {
        title: "Categories",
        icon: "fa-solid fa-layer-group",
        routes: [
            {
                path: "/categories",
                name: "All Categories",
                icon: "fa-solid fa-list",
                component: Categories,
                layout: "/admin",
            },
            {
                path: "/add-category",
                name: "Add Category",
                icon: "fa-solid fa-square-plus",
                component: AddCategory,
                layout: "/admin",
            }
        ]
    },
    {
        title: "Tags",
        icon: "fa-solid fa-tags",
        routes: [
            {
                path: "/tags",
                name: "All Tags",
                icon: "fa-solid fa-list",
                component: Tags,
                layout: "/admin",
            },
            {
                path: "/add-tag",
                name: "Add tag",
                icon: "fa-solid fa-square-plus",
                component: AddTag,
                layout: "/admin",
            }
        ]
    },
    {
        title: "Orders",
        icon: "fa-solid fa-cart-arrow-down",
        routes: [
            {
                path: "/orders",
                name: "All Orders",
                icon: "fa-solid fa-list",
                component: Orders,
                layout: "/admin",
            },
        ]
    },
    {
        title: "Offers",
        icon: "fa-solid fa-money-bill-trend-up",
        routes: [
            {
                path: "/offers",
                name: "All Offers",
                icon: "fa-solid fa-list",
                component: Offers,
                layout: "/admin",
            },
        ]
    },
    {
        title: "Users",
        icon: "fas fa-users",
        routes: [
            {
                path: "/users",
                name: "Liste des utilisateurs",
                icon: "fas fa-list-ul",
                component: Users,
                layout: "/admin",
            },
        ]
    },
    {
        title: "Settings",
        icon: "fas fa-users",
        routes: [
            {
                path: "/settings",
                name: "Site settings",
                icon: "fa-solid fa-gear",
                component: Settings,
                layout: "/admin",
            },
        ]
    },
];
export default dashRoutes;
