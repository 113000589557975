import LoadingButton from 'components/LoadingButton/LoadingButton'
import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import classes from './AddItem.module.css'
function AddItem(props) {

    const [itemInfo, setItemInfo] = React.useState({})
    const [isError, setIsError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const addItemHandler = () => {
        if (!itemInfo.name)
            return setIsError('Please fill the whole inputs')
        setLoading(true)
        props.addItem(itemInfo)
            .then(res => {
                setItemInfo({})
                setIsError(null)
                setIsSuccess(true)
                setLoading(false)

            })
            .catch(err => {
                setIsError('An error occured')
                setLoading(false)
            })
    }
    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [itemInfo])
    return (
        <Container>
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Name</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, name: e.target.value })}
                                    value={itemInfo.name || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            {isError && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'red' }}>Please fill the whole inputs</h5>
                </Col>
            </Row>}
            {!isError && isSuccess && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'green' }}>{props.itemName} successfully added</h5>
                </Col>
            </Row>}
            <Row >
                <Col className={classes.modalFooter}>
                    <LoadingButton loading={loading} className={classes.modalButtonAccept} color="info"
                        onClick={addItemHandler}>Add</LoadingButton>
                </Col>
            </Row>
        </Container>
    )
}

export default AddItem