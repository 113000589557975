import { getArtists } from 'Admin/api'
import { getTags } from 'Admin/api'
import { getCategories } from 'Admin/api'
import { updateMusic } from 'Admin/api'
import { getMusic } from 'Admin/api'
import { addMusic } from 'Admin/api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import GetItem from './GetItem/GetItem'

const Music = (props) => {
    const [artists, setArtists] = React.useState(null)
    const [categories, setCategories] = React.useState(null)
    const [tags, setTags] = React.useState(null)
    React.useEffect(() => {
        getArtists()
            .then(_artists => setArtists(_artists))

        getTags()
            .then(_tags => setTags(_tags))

        getCategories()
            .then(_categories => setCategories(_categories))

    }, [])

    const addTagHandler = (tag) => {
        setTags([tag, ...tags])
    }
    if (!artists || !categories || !tags)
        return (
            <LoadingSpinner />
        )
    return (
        <GetItem
            itemName="Music"
            artists={artists}
            tags={tags}
            addTag={addTagHandler}
            updateItem={updateMusic}
            itemId={props.match.params.musicId}
            categories={categories}
            getItem={getMusic}

        />
    )
}

export default Music