import React from 'react'
import { Spinner } from 'reactstrap'
import classes from './LoadingSpinner.module.css'
const LoadingSpinner = () => {
    return (
        <div className={classes.mainContainer}>
            <Spinner color="primary" style={{ fontSize: '20px', height: '50px', width: '50px' }} />
        </div>
    )
}

export default LoadingSpinner
