import React from 'react'
import classes from './SearchInput.module.css'
import { Col, Input, Row } from 'reactstrap'
const SearchInput = (props) => {
    return (
        <Row>
            <Col xs="12" md="4">
                <div className={classes.inputContainer}>
                    <i className={`fas fa-search ${classes.searchIcon}`}></i>
                    <Input className={classes.searchInput}
                        value={props.value}
                        onChange={(e) => { props.onChange(e.target.value) }}
                        placeholder={"Rechercher"} />
                </div>
            </Col>
        </Row>
    )
}

export default SearchInput
