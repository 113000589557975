
import React from "react";
import { Container, Row, Col } from "reactstrap";

import classes from './Items.module.css'
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";

import ItemsTable from "./ItemsTable/ItemsTable";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const Items = (props) => {

    const [items, setItems] = React.useState([])
    const [confirmModalInfo, setConfirmModalInfo] = React.useState({})
    const [filteredItems, setFilteredItems] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [originalFilteredItems, setOriginalFilteredItems] = React.useState([])
    const [showArchivedItems, setShowArchivedItems] = React.useState(false)
    const history = useHistory()



    React.useEffect(() => {
        props.getData()
            .then(_items => {
                setItems(_items)
                setLoading(false)
            })

    }, [])

    React.useEffect(() => {
        let _filteredItems = [...items]
        setOriginalFilteredItems(_filteredItems)
    }, [items])

    React.useEffect(() => {
        if (showArchivedItems) {
            setFilteredItems([...originalFilteredItems])
        } else {
            setFilteredItems(originalFilteredItems.filter(item => item.status))

        }
    }, [originalFilteredItems, showArchivedItems])



    const activeItemHandler = (itemId) => {
        const itemIndex = items.findIndex(item => itemId === item._id)
        const _items = [...items]
        _items[itemIndex].status = true;
        setItems(_items)
        props.activateItem(itemId)
            .then(res => {
            })
    }

    const preDeleteItemHandler = (itemId) => {
        setConfirmModalInfo({
            isOpen: true,
            message: `Would you like to delete this ${props.itemName}?`,
            onAccept: () => { setConfirmModalInfo({ isOpen: false }); deleteItemHandler(itemId); },
            onReject: () => { setConfirmModalInfo({ isOpen: false }) }
        })
    }
    const deleteItemHandler = (itemId) => {
        const itemIndex = items.findIndex(item => itemId === item._id)
        const _items = [...items]
        _items[itemIndex].status = false;
        setItems(_items)
        props.deleteItem(itemId)
            .then(res => {
            })
    }


    const clickItemHandler = (item) => {
        history.push(`/admin/${props.getItemPath}/${item._id}`)
    }
    if (loading)
        return <LoadingSpinner />

    return (
        <Container className={classes.mainContainer} >

            <ConfirmModal
                isOpen={confirmModalInfo.isOpen}
                onReject={confirmModalInfo.onReject}
                message={confirmModalInfo.message}
                onAccept={confirmModalInfo.onAccept}
            />
            <Row className={classes.mainItemContainer}>
                <Col >
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={showArchivedItems}
                                color="primary"
                                onChange={() => setShowArchivedItems(!showArchivedItems)}
                            />
                        }
                        label="Afficher les items archives"
                    />

                </Col>
            </Row>

            <Row className={classes.mainItemContainer}>
                <Col>
                    <ItemsTable
                        onClick={clickItemHandler}
                        items={filteredItems}
                        titles={props.tableTitles}
                        onActivateItem={activeItemHandler}
                        onDeleteItem={preDeleteItemHandler} />
                </Col>
            </Row>
        </Container>
    );
}

export default Items;
