
import AppContext from "context/AppContext";
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./Login/Login";
import Admin from "./Admin/Admin";

const App = () => {

    return (
        <AppContext>
            <BrowserRouter>
                <Switch>
                    <Route path="/admin" render={(props) => <Admin {...props} />} />
                    <Route path="/login" render={(props) => <Login {...props} />} />
                    <Redirect to="/admin" />
                </Switch>
            </BrowserRouter>
        </AppContext>
    )
}

export default App