
import { activateTag } from 'Admin/api'
import { updateTag } from 'Admin/api'
import { deleteTag } from 'Admin/api'
import { getAllTags } from 'Admin/api'
import React from 'react'
import Items from './Items/Items'
function Tags() {
    return (
        <Items
            getData={getAllTags}
            activateItem={activateTag}
            updateItemInfo={updateTag}
            deleteItem={deleteTag}
            getItemPath="tag"
            tableTitles={['Name', 'Date', 'Music count']}
        />
    )
}

export default Tags