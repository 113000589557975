import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import classes from './AudioPlayer.module.css'
import Slider from 'react-input-slider';
import MusicWave from './MusicWave/MusicWave'
const AudioPlayer = (props) => {

    const [volume, setVolume] = React.useState(1)
    const [muted, setMuted] = React.useState(false)
    const [play, setPlay] = React.useState(false)
    return (
        <Container fluid className={classes.mainContainer}>
            <Row style={{ minHeight: '150px' }}>
                <Col xs="12" className={classes.musicControlsMainContainer}>

                    <div className={classes.musicControlsContainer}>
                        <div className={classes.iconContainer} onClick={() => setPlay(!play)}>
                            {!play ?
                                <i className={`fa-solid fa-play ${classes.musicControlIcon}`}></i>
                                :
                                <i className={`fa-solid fa-stop ${classes.musicControlIcon}`}></i>
                            }
                        </div>
                    </div>
                    <div className={classes.musicControlsContainer}>
                        <div className={classes.iconContainer} onClick={() => setMuted(m => !m)}>
                            {!muted ?
                                <i className={`fa-solid fa-volume-high ${classes.musicControlIcon}`} style={{ fontSize: '15px' }}></i>
                                :
                                <i className={`fa-solid fa-volume-xmark ${classes.musicControlIcon}`} style={{ fontSize: '15px' }}></i>
                            }
                        </div>
                        <Slider
                            styles={{
                                track: {
                                    backgroundColor: '#666666'
                                },
                                active: {
                                    backgroundColor: '#DEAA31'
                                },
                                thumb: {
                                    width: 15,
                                    height: 15
                                },
                                disabled: {
                                    opacity: 0.5
                                }
                            }}
                            axis="x"
                            x={muted ? 0 : volume}
                            xmax={1}
                            xmin={0}
                            xstep={0.02}
                            onChange={({ x }) => { setVolume(x) }} />
                    </div>
                </Col>
                <Col className={classes.waveMusicControlsMainContainer}>
                    <MusicWave
                        play={play}
                        setPlay={setPlay}
                        volume={volume}
                        muted={muted}
                        src={props.src}
                        scale={0.8}
                        height={80}
                        width={400}
                    />
                </Col>

            </Row>
        </Container >
    )
}

export default AudioPlayer