import { activateArtist } from 'Admin/api'
import { updateArtist } from 'Admin/api'
import { deleteArtist } from 'Admin/api'
import { getArtists } from 'Admin/api'
import React from 'react'
import Items from './Items/Items'
function Artists() {
    return (
        <Items
            getData={getArtists}
            activateItem={activateArtist}
            updateItemInfo={updateArtist}
            deleteItem={deleteArtist}
            getItemPath="artist"
            itemName="artist"
            tableTitles={['Name', 'Date', 'Musics count']}
        />
    )
}

export default Artists