import React from "react";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import classes from './Sidebar.module.css'
import MainNavItem from "./MainNavItem/MainNavItem";

var ps;

function Sidebar(props) {
    const sidebar = React.useRef();
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebar.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });
    return (
        <div className="sidebar" data-color={"blue"}>
            <div className="logo">
                {/* <a
                    href="https://www.creative-tim.com?ref=nudr-sidebar"
                    className="simple-text logo-mini"
                    target="_blank"
                >
                    <div className="logo-img">
                        <img src={""} alt="" />
                    </div>
                </a> */}
                <h5
                    className="simple-text logo-normal"
                    style={{ textAlign: 'center', margin: '0', color: 'black', fontWeight: '800' }}
                    target="_blank"
                >
                    Musoundio
                </h5>
            </div>
            <div className="sidebar-wrapper" ref={sidebar}>
                <Nav>
                    {props.routes.map((mainRoute, index) => {
                        return (<MainNavItem location={props.location} key={index} mainRoute={mainRoute} />)
                    })}
                </Nav>
            </div>
        </div>
    );
}

export default Sidebar;

