
import axios from '../utils/axios'

export const userLogin = (identifier, password) => {
    return new Promise((r, j) => {
        axios.post(`/auth/login/`, { identifier, password })
            .then(res => {
                r(res.data.accessToken)
            })
            .catch(err => {
                j(err)
            })
    })
}