import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.5.0";
import "assets/css/demo.css";
import "index.css"
import 'sweetalert2/src/sweetalert2.scss'


import App from "App";

ReactDOM.render(<App />,
    document.getElementById("root")
);