import { Checkbox, ListItemText, MenuItem, Select, TextareaAutosize } from '@material-ui/core'
import { uploadFile, addTag } from 'Admin/api'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import React from 'react'
import { Col, Container, Input, Row } from 'reactstrap'
import AddTagModal from '../AddTagModal/AddTagModal'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import classes from './AddItem.module.css'
import Switch from '@mui/material/Switch';

function AddItem(props) {

    const [itemInfo, setItemInfo] = React.useState({ tags: [], basicPrice: '400', proPrice: '600' })
    const [isError, setIsError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)
    const [previewAudio, setPreviewAudio] = React.useState(null)
    const [fullMusicPreviewAudio, setFullMusicPreviewAudio] = React.useState(null)
    const [showAddTagModal, setShowAddTagModal] = React.useState(false)
    const inputPreviewFile = React.useRef(null)
    const inputFullMusicFile = React.useRef(null)

    React.useEffect(() => {
        setLoading(false)
    }, [])
    const addItemHandler = () => {
        if (!itemInfo.title || !itemInfo.artist)
            return setIsError('Please fill the whole inputs')
        setLoading(true)
        const fd = new FormData()
        fd.append('music', itemInfo.preview)
        fd.append('music', itemInfo.fullMusic)

        uploadFile(fd)
            .then(musicLinks => {
                props.addItem({ ...itemInfo, preview: musicLinks[0], fullMusic: musicLinks[1] })
                    .then(res => {
                        setItemInfo({})
                        setIsError(null)
                        setIsSuccess(true)
                        setLoading(false)
                        setPreviewAudio(null)
                        setFullMusicPreviewAudio(null)
                    })
                    .catch(err => {
                        setIsError('An error occured')
                        setLoading(false)
                    })
            })
    }
    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [itemInfo])

    const setMusicAudioHandler = (musicFile) => {
        setFullMusicPreviewAudio(null)
        setFullMusicPreviewAudio(URL.createObjectURL(musicFile))
        setItemInfo({ ...itemInfo, fullMusic: musicFile })
    }
    const setPreviewMusicAudioHandler = (musicFile) => {

        setPreviewAudio(null)
        setPreviewAudio(URL.createObjectURL(musicFile))
        setItemInfo({ ...itemInfo, preview: musicFile })
    }

    const addTagHandler = (tagInfo) => {
        addTag(tagInfo)
            .then(tag => {
                props.addTag(tag)
                setShowAddTagModal(false)
                setItemInfo({ ...itemInfo, tags: [...itemInfo.tags, tag._id] })
            })
    }
    return (
        <Container>
            <AddTagModal isOpen={showAddTagModal} toggle={setShowAddTagModal} onConfirm={addTagHandler} />
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="12" md="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Title</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, title: e.target.value })}
                                    value={itemInfo.title || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Reference</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, ref: e.target.value })}
                                    value={itemInfo.ref || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Basic Price</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, basicPrice: e.target.value })}
                                    value={itemInfo.basicPrice || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Professional Price</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, proPrice: e.target.value })}
                                    value={itemInfo.proPrice || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Discount</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setItemInfo({ ...itemInfo, discount: e.target.value })}
                                    value={itemInfo.discount || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Description</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <TextareaAutosize
                                    onChange={(e) => setItemInfo({ ...itemInfo, description: e.target.value })}
                                    value={itemInfo.description || ''}
                                    style={{ width: '100%', padding: '10px 20px 10px 20px' }}
                                    minRows={'3'}
                                    className={classes.input} />
                            </div>
                        </div>

                        <div className={classes.itemContainer}>

                            <h5 className={classes.itemText}>Exclusive sale</h5>

                            <Switch checked={itemInfo.isExclusive} onChange={() => setItemInfo({ ...itemInfo, isExclusive: !itemInfo.isExclusive })} />
                        </div>
                        <div className={classes.itemContainer}>

                            <h5 className={classes.itemText}>Category</h5>

                            <Select
                                value={itemInfo.category || 'none'}
                                style={{ width: '100%' }}
                                onChange={(e) => { setItemInfo({ ...itemInfo, category: e.target.value }) }}
                                MenuProps={{
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem disabled value={'none'}>
                                    Select a category
                                </MenuItem>
                                {props.categories.map((category) => (
                                    <MenuItem key={category._id} value={category._id}>
                                        {category.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Artist</h5>

                            <Select
                                value={itemInfo.artist || 'none'}
                                style={{ width: '100%' }}
                                onChange={(e) => { setItemInfo({ ...itemInfo, artist: e.target.value }) }}
                                MenuProps={{
                                    getContentAnchorEl: null
                                }}
                            >
                                <MenuItem disabled value={'none'}>
                                    Select an artist
                                </MenuItem>
                                {props.artists.map((artist) => (
                                    <MenuItem key={artist._id} value={artist._id}>
                                        {artist.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>

                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Tags</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Select
                                    multiple
                                    value={itemInfo.tags || []}
                                    style={{ width: '100%' }}
                                    onChange={(e) => { setItemInfo({ ...itemInfo, tags: e.target.value }) }}
                                    MenuProps={{
                                        getContentAnchorEl: null
                                    }}
                                    renderValue={(selected) => props.tags.filter(t => selected.includes(t._id)).map(t => t.name).join(', ')}
                                >

                                    {props.tags.map((tag) => (
                                        <MenuItem key={tag._id} value={tag._id}>
                                            <Checkbox checked={itemInfo.tags?.indexOf(tag._id) > -1} />
                                            <ListItemText primary={tag.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <i className={`fa-solid fa-circle-plus ${classes.addIcon}`} onClick={setShowAddTagModal}></i>
                            </div>

                        </div>

                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Music Preview</h5>
                            {itemInfo.preview && <h5 className={classes.itemText}>File:{' '}
                                <span style={{ fontWeight: '800' }}>
                                    {itemInfo.preview.name}
                                </span>
                            </h5>}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input ref={inputPreviewFile} style={{ display: 'none' }} type="file" onChange={(e) => setPreviewMusicAudioHandler(e.target.files[0])} />
                                <div className={classes.uploadContainer}
                                    onClick={() => inputPreviewFile.current.click()}>
                                    <i className="fa-solid fa-upload" style={{ color: 'white', fontSize: '20px' }}></i>
                                </div>
                            </div>
                            {previewAudio && <AudioPlayer src={previewAudio} />}
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Full Music</h5>
                            {itemInfo.fullMusic && <h5 className={classes.itemText}>File:{' '}
                                <span style={{ fontWeight: '800' }}>
                                    {itemInfo.fullMusic.name}
                                </span>
                            </h5>}
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <input ref={inputFullMusicFile} style={{ display: 'none' }} type="file" onChange={(e) => setMusicAudioHandler(e.target.files[0])} />
                                <div className={classes.uploadContainer}
                                    onClick={() => inputFullMusicFile.current.click()}>
                                    <i className="fa-solid fa-upload" style={{ color: 'white', fontSize: '20px' }}></i>
                                </div>
                            </div>
                            {fullMusicPreviewAudio && <AudioPlayer src={fullMusicPreviewAudio} />}
                        </div>

                    </div>

                </Col>
            </Row>
            {
                isError && <Row >
                    <Col className={classes.modalFooter}>
                        <h5 className={classes.infoMessage} style={{ color: 'red' }}>Please fill the whole inputs</h5>
                    </Col>
                </Row>
            }
            {
                !isError && isSuccess && <Row >
                    <Col className={classes.modalFooter}>
                        <h5 className={classes.infoMessage} style={{ color: 'green' }}>{props.itemName} successfully added</h5>
                    </Col>
                </Row>
            }
            <Row >
                <Col className={classes.modalFooter}>
                    <LoadingButton loading={loading} className={classes.modalButtonAccept} color="info"
                        onClick={addItemHandler}>Add</LoadingButton>
                </Col>
            </Row>
        </Container >
    )
}

export default AddItem