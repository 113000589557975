import React from 'react'
import classes from './MusicWave.module.css'
const MusicWave = (props) => {


    const [displayedCurrentAudioTime, setDisplayedCurrentAudioTime] = React.useState("0:0")
    const [displayedDuration, setDisplayedDuration] = React.useState("0:0")
    const [isEndedAudio, setIsEndedAudio] = React.useState(false)
    const wavesurfer = React.useRef(null)
    const waveformRef = React.useRef(null)

    React.useEffect(() => {
        if (typeof window !== 'undefined' && wavesurfer && waveformRef) {

            const WaveSurfer = require("wavesurfer.js");
            wavesurfer.current = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: "#eee",
                progressColor: "#E37230",
                cursorColor: "#E37230",
                cursorWidth: 3,
                barWidth: 3,
                barRadius: 3,
                responsive: true,
                height: 150,
                normalize: true,
                partialRender: true
            });
            wavesurfer.current.crossOrigin = 'anonymos'
            wavesurfer.current.load(props.src);

            if (!isEndedAudio) {
                wavesurfer.current.on("ready", function () {
                    wavesurfer.current.setVolume(1);
                    const durationString = convertDurationToMinutes(wavesurfer.current.getDuration())
                    setDisplayedDuration(durationString)
                });
            }
            wavesurfer.current.on("audioprocess", function (e) {
                const currentTimeString = convertDurationToMinutes(e)
                setDisplayedCurrentAudioTime(currentTimeString)
            });
            wavesurfer.current.on("finish", function () {
                props.setPlay(false)
                setIsEndedAudio(true)
                wavesurfer.current.destroy()
                setDisplayedCurrentAudioTime('0:0')
            });

            return () => {
                wavesurfer.current.destroy()
            }
        }

    }, [isEndedAudio, waveformRef, wavesurfer, props.src])

    React.useEffect(() => {
        if (!isEndedAudio && wavesurfer.current) {
            if (props.play) {

                wavesurfer.current.play()
            }
            else {
                wavesurfer.current.pause()
            }
        }

    }, [wavesurfer, props.src, props.play, isEndedAudio])

    React.useEffect(() => {
        if (props.play === true && isEndedAudio) {
            setIsEndedAudio(false)
        }
    }, [props.play, isEndedAudio])



    React.useEffect(() => {
        if (wavesurfer)
            wavesurfer.current.setVolume(props.volume)
    }, [wavesurfer.current, props.volume])

    React.useEffect(() => {
        if (wavesurfer)
            wavesurfer.current.setVolume(props.muted ? 0 : props.volume)
    }, [wavesurfer, props.muted, props.volume])



    const convertDurationToMinutes = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time - minutes * 60);
        return `${minutes}:${seconds}`

    }


    const ceilMinutesAndSeconds = (time) => {
        let [_minutes, _seconds] = time.split(':')

        if (Math.floor(_seconds / 10) <= 0)
            _seconds = `0${_seconds}`
        if (Math.floor(_minutes / 10) <= 0)
            _minutes = `0${_minutes}`

        return `${_minutes}:${_seconds}`
    }
    return (
        <div style={{ display: 'flex', height: '100%', width: '100%' }}>
            <div className={classes.timerContainer} >
                <h5 className={classes.timerText} style={{ color: 'white' }}>
                    {ceilMinutesAndSeconds(displayedCurrentAudioTime)}
                </h5>
            </div>
            <div ref={waveformRef} style={{ height: props.height, width: '100%', overflow: 'hidden' }}></div>
            <div className={classes.timerContainer}>
                <h5 className={classes.timerText} style={{ color: 'white' }}>
                    {ceilMinutesAndSeconds(displayedDuration)}
                </h5>
            </div>
        </div>
    )
}

export default MusicWave