import axios from '../../../utils/axios'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import React from 'react'
import { Button, Col, Container, Input, Row } from 'reactstrap'
import Switch from '@mui/material/Switch';

import classes from './Settings.module.css'
import { FormControlLabel } from '@material-ui/core';
function Settings(props) {

    const [config, setConfig] = React.useState({})
    const [isError, setIsError] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [isSuccess, setIsSuccess] = React.useState(false)



    React.useEffect(() => {
        axios.get('/admin/config')
            .then(res => {
                setConfig(res.data.config)
            })
    }, [])

    const updateSiteSettings = () => {
        setLoading(true)
        axios.patch('/admin/config', { ...config })
            .then(res => {
                setIsSuccess(true)
                setLoading(false)
            })
            .catch(err => {
                setIsError(true)
                setLoading(false)

            })
    }
    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [config])
    return (
        <Container>
            <Row className={classes.mainContentContainer}>
                <Col className={classes.mainContentContainer} xs="8">
                    <div className={classes.modalBodyContainer}>
                        <div className={classes.itemContainer}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <FormControlLabel
                                    control={
                                        <Switch checked={config.exclusive} onChange={() => setConfig({ ...config, exclusive: !config.exclusive })} />
                                    }
                                    label="Exclusive sales"
                                />

                            </div>
                        </div>
                        <div className={classes.itemContainer}>
                            <h5 className={classes.itemText}>Discount</h5>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Input
                                    onChange={(e) => setConfig({ ...config, discount: e.target.value })}
                                    value={config.discount || ''}
                                    className={classes.input} />
                            </div>
                        </div>
                    </div>

                </Col>
            </Row>
            {isError && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'red' }}>Please fill the whole inputs</h5>
                </Col>
            </Row>}
            {!isError && isSuccess && <Row >
                <Col className={classes.modalFooter}>
                    <h5 className={classes.infoMessage} style={{ color: 'green' }}>Settings successfully updated</h5>
                </Col>
            </Row>}
            <Row >
                <Col className={classes.modalFooter}>
                    <LoadingButton loading={loading} className={classes.modalButtonAccept} color="info"
                        onClick={updateSiteSettings}>update</LoadingButton>
                </Col>
            </Row>
        </Container>
    )
}

export default Settings