import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import LoadingButton from 'components/LoadingButton/LoadingButton'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Col, Container, Row, Table } from 'reactstrap'
import FormateDate from 'utils/FormatDate'
import classes from './GetItem.module.css'
function GetItem(props) {

    const [itemInfo, setItemInfo] = React.useState({})
    const [isError, setIsError] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [loadingUpdate, setLoadingUpdate] = React.useState(false)

    const [isSuccess, setIsSuccess] = React.useState(false)

    React.useEffect(() => {
        props.getItem(props.itemId)
            .then(_item => {
                setItemInfo(_item)
                setLoading(false)
            })
    }, [])


    React.useEffect(() => {
        setIsError(false)
        setIsSuccess(false)
    }, [itemInfo])
    if (loading)
        return <LoadingSpinner />
    return (
        <Container className={classes.mainContainer}>
            <Row className={classes.titleContainer} >
                <Col xs='12' md='9' style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <h5 className={classes.pageTitle} >{`Order #${itemInfo.ref}`}</h5>
                </Col>
            </Row>
            <Row className={classes.titleContainer} >
                <Col>
                    <Row>
                        <Col xs='12' md='6' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <h5 className={classes.infoTitle} >
                                Date:{' '}<span className={classes.infoValue}>
                                    <FormateDate >
                                        {itemInfo.createdAt}
                                    </FormateDate>
                                </span>
                            </h5>
                        </Col>
                        <Col xs='12' md='6' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <h5 className={classes.infoTitle} >
                                Total Price:{' '}<span className={classes.infoValue}>
                                    {`${itemInfo.totalPrice} $`}
                                </span>
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='6' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <h5 className={classes.infoTitle} >
                                Username:{' '}<span className={classes.infoValue}>
                                    {itemInfo.user.username}
                                </span>
                            </h5>
                        </Col>
                        <Col xs='12' md='6' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <h5 className={classes.infoTitle} >
                                Email:{' '}<span className={classes.infoValue}>
                                    {itemInfo.user.email}
                                </span>
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs='12' md='6' style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <h5 className={classes.infoTitle} >
                                Licences:{' '}
                            </h5>
                            {itemInfo.licenses.map(license => (
                                <a href={license} target="_blank" className={classes.infoValue} key={license} style={{ marginTop: '10px', display: 'block' }}>
                                    {license}
                                </a>
                            ))}
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className={classes.titleContainer} >
                <Col>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow >
                                    <TableCell align="center">
                                        <h5
                                            className={classes.tableCellTitle}
                                        >
                                            Music title
                                        </h5>
                                    </TableCell>
                                    <TableCell align="center">
                                        <h5
                                            className={classes.tableCellTitle}
                                        >
                                            Price
                                        </h5>
                                    </TableCell>
                                    <TableCell align="center">
                                        <h5
                                            className={classes.tableCellTitle}
                                        >
                                            Type
                                        </h5>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {itemInfo.items.map((item) => (
                                    <TableRow key={item.music._id} >
                                        <TableCell align="center">
                                            <Link to={`/admin/music/${item.music._id}`}>
                                                {item.music.title}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center">
                                            <h5 style={{ fontSize: '14px' }}>
                                                {`${item.price}$`}
                                            </h5>


                                        </TableCell>
                                        <TableCell align="center" >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '18px' }}>
                                                <div className={classes.badgeContainer} style={{ backgroundColor: item.type === "basic" ? 'green' : 'red' }}>
                                                    <h5 className={classes.badgeText}>
                                                        {item.type}
                                                    </h5>
                                                </div>
                                            </div>

                                        </TableCell>
                                    </TableRow>

                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Col>
            </Row>
        </Container>
    )
}

export default GetItem