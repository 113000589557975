import { getArtists } from 'Admin/api'
import { getTags } from 'Admin/api'
import { getCategories } from 'Admin/api'
import { addMusic } from 'Admin/api'
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner'
import React from 'react'
import { Container } from 'reactstrap'
import AddItem from './AddItem/AddItem'

const AddMusic = () => {
    const [artists, setArtists] = React.useState(null)
    const [categories, setCategories] = React.useState(null)
    const [tags, setTags] = React.useState(null)
    React.useEffect(() => {
        getArtists()
            .then(_artists => setArtists(_artists))

        getTags()
            .then(_tags => setTags(_tags))

        getCategories()
            .then(_categories => setCategories(_categories))

    }, [])
    const addTagHandler = (tag) => {
        setTags([tag, ...tags])
    }
    if (!artists || !categories || !tags)
        return (
            <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <LoadingSpinner />
            </Container>

        )
    return (
        <AddItem
            itemName="Music"
            artists={artists}
            tags={tags}
            addTag={addTagHandler}
            categories={categories}
            addItem={addMusic}
        />
    )
}

export default AddMusic