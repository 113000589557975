import moment from 'moment'

const FormateDate = (props) => {

    if (props.noTime)
        return moment(props.children).format('DD/MM/YYYY')

    else
        return moment(props.children).format('DD/MM/YYYY , HH:mm')
}

export default FormateDate;