import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import classes from './ItemsTable.module.css'
import { Link } from 'react-router-dom'
import FormateDate from 'utils/FormatDate';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

const ItemsTable = (props) => {
    const matClasses = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper className={matClasses.root}>
            <TableContainer className={matClasses.container}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {props.titles.map((title, index) => (
                                <TableCell
                                    key={index}
                                    align={"center"}
                                >
                                    {title}
                                </TableCell>
                            ))}
                            <TableCell
                                align={"center"}
                            >
                                Supprimer
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.items.slice().reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={item._id}>
                                    <TableCell align={"center"} style={{ color: item.status ? 'black' : 'red', cursor: 'pointer' }} onClick={() => props.onClick(item)}>
                                        {item.ref}
                                    </TableCell>

                                    <TableCell align={"center"} style={{ color: item.status ? 'black' : 'red' }}>
                                        <FormateDate>
                                            {item.createdAt}
                                        </FormateDate>
                                    </TableCell>
                                    <TableCell align={"center"} style={{ color: item.status ? 'black' : 'red' }}>
                                        {item.user.email}
                                    </TableCell>
                                    <TableCell align={"center"} style={{ color: item.status ? 'black' : 'red' }}>
                                        {item.items.length}
                                    </TableCell>
                                    <TableCell align={"center"}>
                                        {item.status ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className={classes.deleteContainer} onClick={() => props.onDeleteItem(item._id)} >
                                                <i className="fas fa-trash-alt" style={{ color: 'white', fontSize: '15px' }}></i>
                                            </div>
                                        </div> :
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div className={classes.deleteContainer} style={{ backgroundColor: '#E37230' }} onClick={() => props.onActivateItem(item._id)} >
                                                    <i className="fas fa-trash-restore-alt" style={{ color: 'white', fontSize: '15px' }}></i>
                                                </div>
                                            </div>}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={props.items.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}

export default ItemsTable