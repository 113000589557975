import React from 'react';
import GlobalContext from './GlobalContext';
import axios from '../utils/axios';
import LocalStorageService from '../utils/localStorageService';
import LoadingPage from 'components/LoadingPage/LoadingPage';

const AppContext = (props) => {
    const [user, setUser] = React.useState(null)
    const [loadingUser, setLoadingUser] = React.useState(true)

    React.useEffect(() => {

        if (LocalStorageService.getAccessToken()) {
            axios.get(`/auth/connected-user`)
                .then(res => {
                    setUser(res.data.user)
                    setLoadingUser(false)

                }).catch(err => {
                    console.log(err)
                })

        }
        else {
            if (window.location.pathname !== "/login")
                window.location.href = "/login"
            setLoadingUser(false)
        }
    }, [])




    const userLogoutHandler = () => {
        LocalStorageService.clearToken()
        window.location.href = '/login'
        setCurrentModule(null)
    }


    return (
        <GlobalContext.Provider value={{
            user,
            setUser,
            userLogout: userLogoutHandler
        }}>
            {!loadingUser ? props.children
                :
                <LoadingPage />}
        </GlobalContext.Provider>
    );
};

export default AppContext;
