import { getOrder } from 'Admin/api'
import React from 'react'
import GetItem from './GetItem/GetItem'

const Order = (props) => {
    return (
        <GetItem
            itemName="Order"
            itemId={props.match.params.orderId}
            getItem={getOrder}
        />
    )
}

export default Order