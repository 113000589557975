
import React from "react";
import { Container, Row, Col, Button } from "reactstrap";

import classes from './Users.module.css'
import { activateUser, addUser, deleteUser, getUsers, updateUserInfo } from '../../api'
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";

import SearchInput from "components/SearchInput/SearchInput";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import UsersTable from "./UsersTable/UsersTable";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";

const Users = (props) => {

    const [users, setUsers] = React.useState([])
    const [confirmModalInfo, setConfirmModalInfo] = React.useState({})
    const [searchTerm, setSearchTerm] = React.useState('')
    const [filteredUsers, setFilteredUsers] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [originalFilteredUsers, setOriginalFilteredUsers] = React.useState([])
    const [showArchivedUsers, setShowArchivedUsers] = React.useState(false)



    React.useEffect(() => {
        getUsers()
            .then(_users => {
                _users = _users.sort((d1, d2) => {
                    return new Date(d2.createdAt).getTime() - new Date(d1.createdAt).getTime()
                })
                setUsers(_users)
                setLoading(false)
            })

    }, [])

    React.useEffect(() => {
        let _filteredUsers = [...users]
        _filteredUsers = _filteredUsers.filter(user => user.username.toUpperCase().includes(searchTerm.toUpperCase()) || user.phone.toUpperCase().includes(searchTerm.toUpperCase()))
        setOriginalFilteredUsers(_filteredUsers)
    }, [users, searchTerm])

    React.useEffect(() => {
        if (showArchivedUsers) {
            setFilteredUsers([...originalFilteredUsers])
        } else {
            setFilteredUsers(originalFilteredUsers.filter(user => user.status))

        }
    }, [originalFilteredUsers, showArchivedUsers])



    const activateUserHandler = (userId) => {
        const userIndex = users.findIndex(user => userId === user._id)
        const _users = [...users]
        _users[userIndex].status = true;
        setUsers(_users)
        activateUser(userId)
            .then(res => {
            })
    }

    const preDeleteUserHandler = (userId) => {
        setConfirmModalInfo({
            isOpen: true,
            message: 'Would you like to delete this user?',
            onAccept: () => { setConfirmModalInfo({ isOpen: false }); deleteUserHandler(userId); },
            onReject: () => { setConfirmModalInfo({ isOpen: false }) }
        })
    }
    const deleteUserHandler = (userId) => {
        const userIndex = users.findIndex(user => userId === user._id)
        const _users = [...users]
        _users[userIndex].status = false;
        setUsers(_users)
        deleteUser(userId)
            .then(res => {
            })
    }
    if (loading)
        return <LoadingSpinner />

    return (
        <Container className={classes.mainContainer} >

            <ConfirmModal
                isOpen={confirmModalInfo.isOpen}
                onReject={confirmModalInfo.onReject}
                message={confirmModalInfo.message}
                onAccept={confirmModalInfo.onAccept}
            />
            <Row className={classes.mainItemContainer}>
                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SearchInput
                        value={searchTerm}
                        onChange={(v) => setSearchTerm(v)}
                    />
                </Col>
            </Row>

            <Row className={classes.mainItemContainer}>
                <Col >
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={showArchivedUsers}
                                color="primary"
                                onChange={() => setShowArchivedUsers(!showArchivedUsers)}
                            />
                        }
                        label="Afficher les utilisateur archives"
                    />

                </Col>
            </Row>

            <Row className={classes.mainItemContainer}>
                <Col>
                    <UsersTable
                        users={filteredUsers}
                        onActivateUser={activateUserHandler}
                        onDeleteUser={preDeleteUserHandler} />
                </Col>
            </Row>
        </Container>
    );
}

export default Users;
