
import axios from '../utils/axios'

export const getArtists = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/artist`)
            .then(res => {
                r(res.data.artists)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const getMusics = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/music`)
            .then(res => {
                r(res.data.musics)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getAllTags = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/tag/all`)
            .then(res => {
                r(res.data.tags)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getTags = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/tag`)
            .then(res => {
                r(res.data.tags)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getOrders = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/order`)
            .then(res => {
                r(res.data.orders)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getOffers = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/offer`)
            .then(res => {
                r(res.data.offers)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const getCategories = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/category`)
            .then(res => {
                r(res.data.categories)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const getUsers = () => {
    return new Promise((r, j) => {
        axios.get(`/admin/user`)
            .then(res => {
                r(res.data.users)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const deleteArtist = (artistId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/artist/${artistId}`)
            .then(res => {
                r(res.data.artist)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const deleteOrder = (orderId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/order/${orderId}`)
            .then(res => {
                r(res.data.order)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const deleteOffer = (offerId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/offer/${offerId}`)
            .then(res => {
                r(res.data.offer)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const deleteMusic = (musicId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/music/${musicId}`)
            .then(res => {
                r(res.data.music)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const deleteTag = (tagId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/tag/${tagId}`)
            .then(res => {
                r(res.data.tag)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const deleteCategory = (categoryId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/category/${categoryId}`)
            .then(res => {
                r(res.data.tag)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const deleteUser = (userId) => {
    return new Promise((r, j) => {
        axios.delete(`/admin/category/${userId}`)
            .then(res => {
                r(res.data.tag)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const addArtist = (artist) => {
    return new Promise((r, j) => {
        axios.post(`/admin/artist`, { artist })
            .then(res => {
                r(res.data.artist)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const addMusic = (music) => {
    return new Promise((r, j) => {
        axios.post(`/admin/music`, { music })
            .then(res => {
                r(res.data.music)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const addCategory = (category) => {
    return new Promise((r, j) => {
        axios.post(`/admin/category`, { category })
            .then(res => {
                r(res.data.category)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const addTag = (tag) => {
    return new Promise((r, j) => {
        axios.post(`/admin/tag`, { tag })
            .then(res => {
                r(res.data.tag)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const updateMusic = (musicId, musicInfo) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/music/${musicId}`, musicInfo)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const updateCategory = (categoryId, categoryInfo) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/category/${categoryId}`, categoryInfo)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const updateArtist = (artistId, artistInfo) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/artist/${artistId}`, artistInfo)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const updateTag = (tagId, tagInfo) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/tag/${tagId}`, tagInfo)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const activateMusic = (musicId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/music/${musicId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const activateOrder = (orderId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/order/${orderId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const activateOffer = (offerId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/offer/${offerId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const activateCategory = (categoryId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/category/${categoryId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const activateArtist = (artistId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/artist/${artistId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const activateTag = (tagId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/tag/${tagId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const activateUser = (userId) => {
    return new Promise((r, j) => {
        axios.patch(`/admin/user/${userId}/activate`)
            .then(res => {
                r(res.data.message)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const uploadFile = (fd) => {
    return new Promise((r, j) => {
        axios.post(`/upload`, fd)
            .then(res => {
                r(res.data.fileLinks)
            })
            .catch(err => {
                j(err)
            })
    })
}








export const getArtist = (artistId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/artist/${artistId}`)
            .then(res => {
                r(res.data.artist)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const getMusic = (musicId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/music/${musicId}`)
            .then(res => {
                r(res.data.music)
            })
            .catch(err => {
                j(err)
            })
    })
}

export const getTag = (tagId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/tag/${tagId}`)
            .then(res => {
                r(res.data.tag)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getOrder = (orderId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/order/${orderId}`)
            .then(res => {
                r(res.data.order)
            })
            .catch(err => {
                j(err)
            })
    })
}
export const getOffer = (offerId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/offer/${offerId}`)
            .then(res => {
                r(res.data.offer)
            })
            .catch(err => {
                j(err)
            })
    })
}


export const getCategory = (categoryId) => {
    return new Promise((r, j) => {
        axios.get(`/admin/category/${categoryId}`)
            .then(res => {
                r(res.data.category)
            })
            .catch(err => {
                j(err)
            })
    })
}